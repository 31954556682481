import {appInsights} from '../AppInsightsSetup';
import {StepIds} from '../components/Steps/stepConfig';
import {StepType} from '../components/Steps/stepsForApplicationTypeAndTiltak';
import {ApplicationId} from '../services/ebyggesokServices/userOrderService';
import {QuestionId} from '../services/utils/questionIds';
import {Answer, AnswerType, AnswerValues} from '../state/questionTypes';
import {getRequieredAnsvarsrettFromQuestions, requiresAnsvarsrett} from '../utils/triggerHelpers/ansvarsrett';
import {getRequieredDispensionsFromQuestions, TriggeredDispOrUnntak} from '../utils/triggerHelpers/dispensasjon';
import {questionWithError} from '../utils/errorQuestion';
import {getAnswerValues} from '../utils/getAnswerValues';
import {requiresMelding} from '../utils/triggerHelpers/melding';
import {getRequiredUnntakFromQuestions} from '../utils/triggerHelpers/unntak';
import {ApplicationState} from './applicationState';
import {Dispatch} from './applicationStore';
import {Answers} from './questionTypes';
import {UserDispatch} from './userStore';

export type DispNote = {questionId: QuestionId; text: string};
export type AnsvarsrettState = {hasAnsvarsrett: boolean; triggeredBy?: {id: QuestionId; step: StepIds}[]};

export interface QuestionsState {
  answers: Answers;
  ansvarsrettState: AnsvarsrettState;
  melding: boolean;
  error: boolean;
  dispNotes: DispNote[];
  lastChangedDate: Date;
  triggeredDisps: TriggeredDispOrUnntak[];
  triggeredUnntak: TriggeredDispOrUnntak[];
}

export const initialState: QuestionsState = {
  answers: {},
  dispNotes: [],
  ansvarsrettState: {hasAnsvarsrett: false},
  triggeredDisps: [],
  triggeredUnntak: [],
  melding: false,
  error: false,
  lastChangedDate: new Date(),
};

export type AddAnswerAction = {
  type: 'addAnswer';
  value: {
    application: ApplicationState;
    step: StepIds;
    id: QuestionId;
    answer: any;
    answerType?: AnswerType;
    doNotResetStepper?: boolean;
    dispatch: Dispatch;
    userDispatch: UserDispatch;
    options?: any[];
  };
};

export type RemoveAnswerAction = {
  type: 'removeAnswer';
  value: {
    step: StepIds;
    id: QuestionId;
    dispatch: Dispatch;
    userDispatch: UserDispatch;
    stepType: StepType;
    applicationId?: ApplicationId;
  };
};

export type DeactivateAnswerAction = {
  type: 'deactivateAnswer';
  value: {
    step: StepIds;
    id: QuestionId;
    dispatch: Dispatch;
    userDispatch: UserDispatch;
    stepType: StepType;
    applicationId?: ApplicationId;
  };
};

export type Action =
  | AddAnswerAction
  | RemoveAnswerAction
  | DeactivateAnswerAction
  | {
      type: 'clearAnswers';
      ignoreSteps?: StepIds[];
    }
  | {
      type: 'setLastChangedDate';
      value: Date;
    }
  | {
      type: 'setDispNote';
      value: {dispNote: DispNote; dispatch: Dispatch; userDispatch: UserDispatch; application: ApplicationState};
    }
  | {
      type: 'setDispNotes';
      value: {dispNotes: DispNote[]; dispatch: Dispatch};
    };

export type DispAnswer = {description?: string; reason?: string};

export const reducer = (state: QuestionsState, action: Action): QuestionsState => {
  switch (action.type) {
    case 'addAnswer': {
      var answers: Answers = {
        ...state.answers,
        [action.value.id]: {
          value: action.value.answer,
          step: action.value.step,
          deactivated: false,
          answerType: action.value.answerType,
        },
      };
      const answerValues = getAnswerValues(answers);
      const ansvarsrettState = getAnsvarsrettState(answers);
      const ansvarsrettAnswer: Answer = answers['ANSVARSRETT_TRIGGERED'];
      answers['ANSVARSRETT_TRIGGERED'] = {
        ...ansvarsrettAnswer,
        value: ansvarsrettState.hasAnsvarsrett,
        step: 'what_to_do_summary',
      };
      const hasMelding = requiresMelding(answerValues);
      answers['MELDING_TRIGGERED'] = {
        ...answers['MELDING_TRIGGERED'],
        value: hasMelding,
        step: 'what_to_do_summary',
      };
      const hasError = questionWithError(answerValues);
      const triggeredDisps = getTriggeredDisps(answerValues);
      const triggeredUnntak = getTriggeredUnntak(answerValues);
      trackAnsvarsrettDispsAndUnntak(
        state.triggeredDisps,
        triggeredDisps,
        state.triggeredUnntak,
        triggeredUnntak,
        state.ansvarsrettState,
        ansvarsrettState
      );
      return {
        ...state,
        answers: answers,
        ansvarsrettState: ansvarsrettState,
        melding: hasMelding,
        error: hasError,
        triggeredDisps: triggeredDisps,
        triggeredUnntak: triggeredUnntak,
      };
    }
    case 'removeAnswer': {
      let answers = {...state.answers};
      delete answers[action.value.id];
      const ansvarsrettState = getAnsvarsrettState(answers);
      const answerValues = getAnswerValues(answers);

      answers['ANSVARSRETT_TRIGGERED'] = {
        ...answers['ANSVARSRETT_TRIGGERED'],
        value: ansvarsrettState.hasAnsvarsrett,
        step: 'what_to_do_summary',
      };
      const hasMelding = requiresMelding(answerValues);
      answers['MELDING_TRIGGERED'] = {
        ...answers['MELDING_TRIGGERED'],
        value: hasMelding,
        step: 'what_to_do_summary',
      };
      const hasError = questionWithError(answerValues);
      const triggeredDisps = getTriggeredDisps(answerValues);
      const triggeredUnntak = getTriggeredUnntak(answerValues);

      trackAnsvarsrettDispsAndUnntak(
        state.triggeredDisps,
        triggeredDisps,
        state.triggeredUnntak,
        triggeredUnntak,
        state.ansvarsrettState,
        ansvarsrettState
      );

      return {
        ...state,
        answers: answers,
        ansvarsrettState: getAnsvarsrettState(answers),
        melding: hasMelding,
        error: hasError,
        triggeredDisps,
        triggeredUnntak,
      };
    }
    case 'setDispNote': {
      return {
        ...state,
        dispNotes: state.dispNotes
          ? [
              ...state.dispNotes.filter((note) => note.questionId !== action.value.dispNote.questionId),
              action.value.dispNote,
            ]
          : [action.value.dispNote],
      };
    }
    case 'setDispNotes': {
      return {
        ...state,
        dispNotes: action.value.dispNotes,
      };
    }
    case 'setLastChangedDate': {
      return {
        ...state,
        lastChangedDate: action.value,
      };
    }
    case 'deactivateAnswer': {
      let answers = {...state.answers};
      if (answers[action.value.id]) {
        answers[action.value.id] = {...answers[action.value.id]!, deactivated: true};
      }
      const ansvarsrettState = getAnsvarsrettState(answers);

      answers['ANSVARSRETT_TRIGGERED'] = {
        ...answers['ANSVARSRETT_TRIGGERED'],
        value: ansvarsrettState.hasAnsvarsrett,
        step: 'what_to_do_summary',
      };
      const answerValues = getAnswerValues(answers);

      const hasMelding = requiresMelding(answerValues);
      answers['MELDING_TRIGGERED'] = {
        ...answers['MELDING_TRIGGERED'],
        value: hasMelding,
        step: 'what_to_do_summary',
      };
      const hasError = questionWithError(answerValues);
      const triggeredDisps = getTriggeredDisps(answerValues);
      const triggeredUnntak = getTriggeredUnntak(answerValues);
      trackAnsvarsrettDispsAndUnntak(
        state.triggeredDisps,
        triggeredDisps,
        state.triggeredUnntak,
        triggeredUnntak,
        state.ansvarsrettState,
        ansvarsrettState
      );
      return {
        ...state,
        answers: answers,
        ansvarsrettState: ansvarsrettState,
        melding: hasMelding,
        error: hasError,
        triggeredDisps,
        triggeredUnntak,
      };
    }

    case 'clearAnswers': {
      let answers = {};
      if (action.ignoreSteps) {
        Object.assign(answers, state.answers);
        Object.keys(answers).forEach((key) => {
          if (action.ignoreSteps!.indexOf(state.answers[key].step) === -1) {
            delete answers[key];
          }
        });
      }
      return {
        ...state,
        answers: answers,
        ansvarsrettState: {hasAnsvarsrett: false},
      };
    }
    default:
      return state;
  }
};
const trackAnsvarsrettDispsAndUnntak = (
  prevDispsTriggered: TriggeredDispOrUnntak[],
  dispsTriggered: TriggeredDispOrUnntak[],
  prevUnntakTriggered: TriggeredDispOrUnntak[],
  unntakTriggered: TriggeredDispOrUnntak[],
  prevAnsvarsrettState: AnsvarsrettState,
  ansvarsrettState: AnsvarsrettState
) => {
  if (JSON.stringify(prevDispsTriggered) !== JSON.stringify(dispsTriggered)) {
    if (dispsTriggered.length > 0) {
      const oldDispIds = prevDispsTriggered.map((disp) => disp.id);
      const newDisps = dispsTriggered.filter((newDisp) => !oldDispIds.includes(newDisp.id));
      appInsights.trackEvent(
        {name: 'Disp triggered ' + newDisps.map((disp) => disp.id).join(',')},
        {dispTriggered: dispsTriggered}
      );
    } else {
      appInsights.trackEvent({name: 'No disp triggered'}, {prevDisp: prevDispsTriggered});
    }
  }
  if (JSON.stringify(prevUnntakTriggered) !== JSON.stringify(unntakTriggered)) {
    if (unntakTriggered.length > 0) {
      const oldUnntakIds = prevUnntakTriggered.map((unntak) => unntak.id);
      const newUnntaks = unntakTriggered.filter((newUnntak) => !oldUnntakIds.includes(newUnntak.id));
      appInsights.trackEvent(
        {name: 'Unntak triggered ' + newUnntaks.map((unntak) => unntak.id).join(',')},
        {unntakTriggered: unntakTriggered}
      );
    } else {
      appInsights.trackEvent({name: 'No unntak triggered'}, {prevUnntak: prevUnntakTriggered});
    }
  }
  if (JSON.stringify(prevAnsvarsrettState) !== JSON.stringify(ansvarsrettState)) {
    if (ansvarsrettState.hasAnsvarsrett) {
      appInsights.trackEvent({
        name: 'Ansvarsrett triggered by ' + ansvarsrettState.triggeredBy?.map((trig) => trig.id).join(','),
      });
    } else {
      appInsights.trackEvent({name: 'Ansvarsrett not triggered'}, {prevAnsvarsrettState: prevAnsvarsrettState});
    }
  }
};

const getAnsvarsrettState = (answers: Answers): AnsvarsrettState => {
  const hasAnsvarsrett = requiresAnsvarsrett(getAnswerValues(answers));
  const questionIdsTriggeringAnsvarsrett: {id: QuestionId; step: StepIds}[] = getRequieredAnsvarsrettFromQuestions(
    getAnswerValues(answers)
  ).map((q) => ({
    id: q.id as QuestionId,
    step: answers[q.id]!.step,
  }));
  return {hasAnsvarsrett: hasAnsvarsrett, triggeredBy: questionIdsTriggeringAnsvarsrett};
};
const getTriggeredDisps = (answers: AnswerValues): TriggeredDispOrUnntak[] => {
  const triggeredDisps = getRequieredDispensionsFromQuestions(answers);
  return triggeredDisps;
};

const getTriggeredUnntak = (answers: AnswerValues): TriggeredDispOrUnntak[] => {
  const triggeredUnntak = getRequiredUnntakFromQuestions(answers);
  return triggeredUnntak;
};
