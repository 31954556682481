import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {History} from 'history';
import * as jwt from 'jsonwebtoken';
import {appInsights, traceSeverityLevel} from '../AppInsightsSetup';
import {getConfigValue} from '../config';
import {Personnummer, UserDispatch} from '../state/userStore';
import getErrorMsg from '../utils/getErrorMsg';
import {ApplicationRoutes} from '../utils/routes/ApplicationRoutes';
import {InfoPagesRoutes} from '../utils/routes/InfoPagesRoutes';
import {currentPathRequiresLogin} from '../utils/routes/routesHelpers';
import {access_token_session_key, getAsyncAxiosInstanceWithIdportenHeader} from './ebyggesokServices/axiosService';
import {createOrGetUser} from './ebyggesokServices/userOrderService';

type IdPortenResponse = {
  AccessToken: string;
  IdToken: string;
};

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function goToIdPorten(dispatch: UserDispatch, returnUrl?: string) {
  dispatch({type: 'setInactivityLogout', value: false});
  dispatch({type: 'setRouteBeforeSignIn', value: returnUrl ?? window.location.pathname + window.location.search});
  appInsights.trackTrace({message: 'Client idporten signIn start', severityLevel: traceSeverityLevel.Information});
  const url = getConfigValue('serverUrl');
  const state = makeid(5);
  window.sessionStorage.setItem('ebyggesok_idporten_state', state);
  //'&onbehalfof=dibk'
  window.location.href =
    url + `login?redirect_uri=${window.location.origin}${ApplicationRoutes.idporten.path}&state=` + state;
}

function getParameterByName(name: 'state' | 'code', url?: string) {
  if (!url) url = window.location.href;
  const name2 = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name2 + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export async function callRefreshToken() {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<IdPortenResponse>('Authenticate/IdPorten/Token/Refresh')
  );
}

export async function refreshToken(dispatch: UserDispatch) {
  await callRefreshToken().then(
    (resp) => {
      if (resp.status === 200 && resp.data.AccessToken) {
        sessionStorage.setItem(access_token_session_key, resp.data.AccessToken);
      }
    },
    (err) => {
      sessionStorage.removeItem(access_token_session_key);
      console.error('error refresh token', err.response?.data);
      //For some reason dispatch is sometimes undefined when this happens
      if (dispatch) {
        const pathRequiresLogin = currentPathRequiresLogin();
        if (!pathRequiresLogin) {
          dispatch({type: 'resetUser'});
          return;
        }
        dispatch({type: 'resetUser'});
        dispatch({
          type: 'displayLoginErrorPopup',
          value: {
            msg: 'idporten refreshToken failed',
            reason:
              'refreshToken error ' + err.response?.status
                ? 'idporten refreshToken failed ' + err.response?.status
                : 'idporten refreshToken failed ',
          },
        });
      } else {
        alert('idporten refreshToken failed ' + err.response?.status);
      }
      appInsights.trackTrace(
        {
          message: 'idporten refreshToken failed',
          severityLevel: traceSeverityLevel.Information,
        },
        {
          error: getErrorMsg(err),
          text: err.response?.status
            ? 'idporten refreshToken failed ' + err.response.status
            : 'idporten refreshToken failed ',
        }
      );
    }
  );
}

export async function fetchTokens(routeBeforeSignIn: string, history: History, dispatch: UserDispatch) {
  if (getParameterByName('state') === window.sessionStorage.getItem('ebyggesok_idporten_state')) {
    const instance = await getAsyncAxiosInstanceWithIdportenHeader();
    instance
      .get<IdPortenResponse>(
        'Authenticate/IdPorten/Token?code=' + getParameterByName('code') + '&state=' + getParameterByName('state')
      )
      .then(
        (resp) => {
          sessionStorage.setItem(access_token_session_key, resp.data.AccessToken);
          createOrGetUser().then(
            (respUser) => {
              const decodedToken = resp.data.IdToken && jwt.decode(resp.data.IdToken);
              if (!decodedToken.pid) {
                history.push(InfoPagesRoutes.base.path);
                dispatch({type: 'displayLoginErrorPopup', value: {reason: 'fetchTokens !decoded.pid'}});
              }
              dispatch({
                type: 'setUserId',
                value: decodedToken.pid as Personnummer,
              });
              if (routeBeforeSignIn === ApplicationRoutes.kan_jeg_soke_resultat.path) {
                //Go to start søknad page if user came from soke result page
                history.push(ApplicationRoutes.start_soknad.path);
              } else {
                history.push(routeBeforeSignIn);
              }
            },
            (err) => {
              console.error('Feil ved createOrGetUser');
              appInsights.trackException(
                {
                  error: new Error('createOrGetUserFailed'),
                  severityLevel: SeverityLevel.Error,
                },
                {error: getErrorMsg(err)}
              );
            }
          );
        },
        (err) => {
          appInsights.trackException(
            {
              error: new Error('authenticateIdportenTokenFailed'),
              severityLevel: SeverityLevel.Error,
            },
            {error: getErrorMsg(err)}
          );
          console.error('Feil ved fetching av token', err.response);
          history.push(InfoPagesRoutes.base.path);
          dispatch({type: 'displayLoginErrorPopup', value: {reason: 'fetchTokens error'}});
          //Tell antall ganger, om mer enn 2 Logg ut
        }
      );
  }
}

export async function signOutIdporten() {
  const url = getConfigValue('serverUrl');
  window.location.href = `${url}Authenticate/IdPorten/SignOut?PostLogOutRedirect=${
    window.location.origin + ApplicationRoutes.logout.path
  }&State=${getParameterByName('state') ?? ''}`;
}
