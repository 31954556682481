import {SeverityLevel} from '@microsoft/applicationinsights-common';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appInsights} from '../AppInsightsSetup';
import * as application from '../state/applicationState';
import {UserDispatch} from '../state/userStore';
import SevereError from './SevereError';

type props = {
  application?: application.ApplicationState;
  userDispatch: UserDispatch;
  children: JSX.Element;
} & RouteComponentProps;

class ErrorBoundary extends React.Component<props> {
  state = {hasError: false};

  componentDidCatch(error: Error, info) {
    //dispatch error for user
    const appId = this.props.application?.applicationId;
    this.props.userDispatch({type: 'setSevereError', value: {msg: 'Det har oppstått en ukjent feil.'}});
    appInsights.trackException(
      {
        error: new Error('unknownClientCrash'),
        severityLevel: SeverityLevel.Error,
      },
      {
        error: error.toString(),
        errorStack: error.stack,
        info: JSON.stringify(info),
        path: window.location.href,
        name: 'Page load failed ' + appId || '',
        message: 'Page crashed unexpectedly',
      }
    );
    console.error('page crash error', error);
    this.setState({hasError: true});
  }

  render() {
    if (this.state.hasError) {
      return <SevereError msg={'En ukjent feil oppsto.'} />;
    } else {
      return this.props.children;
    }
  }
}
export default withRouter(ErrorBoundary);
