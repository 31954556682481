import {ClickAnalyticsPlugin} from '@microsoft/applicationinsights-clickanalytics-js';
import {createBrowserHistory} from 'history';
import {getConfigValue} from './config';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

const browserHistory = createBrowserHistory();

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {useDefaultContentNameOrId: true},
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getConfigValue('applicationInsightsKey'),
    extensions: [reactPlugin as any, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});

window.addEventListener(
  'CookieInformationConsentGiven',
  function (event) {
    if ((window as any)?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic')) {
      appInsights.loadAppInsights();
      var referrer = document.referrer;
      appInsights.addTelemetryInitializer((envelope) => {
        if (envelope && envelope.data) {
          envelope.data.referrer = referrer;
        }
      });
    }
  },
  false
);

export const traceSeverityLevel = {
  Information: 1,
  Critical: 4,
  Warning: 2,
  Error: 3,
  Verbose: 0,
};

export {reactPlugin, appInsights};
