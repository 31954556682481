import React from 'react';
import {Helmet} from 'react-helmet-async';
import {appInsights} from '../../AppInsightsSetup';
import {DesktopContainer} from '../../components/containers/DeviceContainers';
import ContentSearch from '../../components/ContentSearch/ContentSearch';
import {getFilteredSearchWords} from '../../components/ContentSearch/helpers';
import layout from '../../components/hocs/layout';
import NkLink from '../../components/NkLink/NkLink';
import NkTypography from '../../components/NkTypography/NkTypography';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import {setTitle} from '../../utils/setTitle';
import FaqQuestion from './components/FaqQuestion';
import './faq.scss';
import {FaqQuestionType, faqText} from './faqText';

const FAQ = () => {
  const [filteredQuestionsToDisplay, setFilteredQuestionsToDisplay] = React.useState<FaqQuestionType[]>([]);
  const [hasActiveSearchFilter, setHasActiveSearchFilter] = React.useState(false);

  React.useEffect(() => {
    setTitle('Ofte stilte spørsmål');
  }, []);

  let allQuestions: FaqQuestionType[] = [];
  faqText.forEach((section) => (allQuestions = allQuestions.concat(section.questions)));

  const trackUserSearch = (searchText: string) => {
    appInsights.trackEvent({name: 'faq-search'}, {searchText: searchText});
  };

  const onSearchChanged = (searchText: string) => {
    if (searchText !== '') {
      trackUserSearch(searchText);
      setHasActiveSearchFilter(true);
      const searchWords = getFilteredSearchWords(searchText);
      const questionsContainingString = allQuestions.filter((q) => {
        let hasMatch = true;
        searchWords.forEach((word) => {
          if (
            !q.content.toLowerCase().includes(word.toLowerCase()) &&
            !q.header.toLowerCase().includes(word.toLowerCase())
          ) {
            hasMatch = false;
          }
        });
        return hasMatch;
      });
      setFilteredQuestionsToDisplay(questionsContainingString);
    } else {
      setHasActiveSearchFilter(false);
      setFilteredQuestionsToDisplay([]);
    }
  };

  return (
    <div className='questionHelper faq'>
      <Helmet>
        <meta data-rh='true' name='keywords' content='' />
        <meta name='description' content='Ofte stilte spørsmål - eByggesøk for privatpersoner.' />
      </Helmet>
      <div className='container'>
        <DesktopContainer>
          <nav className='sectionMenu' aria-label='FAQ innholdsfortegnelse'>
            <NkTypography style={{marginBottom: 20, fontWeight: 500}} type='largeParagraph'>
              Innhold
            </NkTypography>
            {faqText.map((faqSection) => {
              return (
                <button
                  key={faqSection.sectionName}
                  onClick={() => document.getElementById(faqSection.sectionName)?.scrollIntoView()}
                  style={{backgroundColor: 'transparent', border: 'none', textAlign: 'left'}}
                >
                  <NkTypography type='normalParagraph'>{faqSection.sectionName}</NkTypography>
                </button>
              );
            })}
            <NkLink style={{marginTop: 20}} href={InfoPagesRoutes.articles.path}>
              Aktuelle artikler
            </NkLink>
          </nav>
        </DesktopContainer>
        <main className='mainContent' id={'pageMainContent'}>
          <NkTypography tag='h1' type='heading2'>
            Ofte stilte spørsmål
          </NkTypography>
          <ContentSearch id='faq_search' contentName='faq' onSearchChanged={onSearchChanged} />
          {hasActiveSearchFilter ? (
            <>
              {filteredQuestionsToDisplay.length > 0 ? (
                filteredQuestionsToDisplay.map((q) => (
                  <FaqQuestion initOpen={true} key={q.header} content={q.content} header={q.header} />
                ))
              ) : (
                <>
                  <NkTypography type='normalParagraph'>
                    Obs, dette søket ga ingen treff. Prøv å søk på ord i stedet for hele setninger.
                  </NkTypography>
                  <NkLink style={{marginTop: 20}} href={InfoPagesRoutes.articles.path}>
                    Du kan også se våre artikler!
                  </NkLink>
                </>
              )}
            </>
          ) : (
            <>
              {faqText.map((faqSection) => {
                return (
                  <section key={faqSection.sectionName} className='section' id={faqSection.sectionName}>
                    <NkTypography style={{marginBottom: 20}} tag='h2' type='heading3'>
                      {faqSection.sectionName}
                    </NkTypography>
                    {faqSection.questions.map((q) => (
                      <FaqQuestion key={q.header} content={q.content} header={q.header} />
                    ))}
                  </section>
                );
              })}
            </>
          )}
        </main>
      </div>
    </div>
  );
};
export default layout({hideStepper: true, showFooter: true})(FAQ);
