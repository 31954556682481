import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {AxiosError} from 'axios';
import {appInsights} from '../../AppInsightsSetup';
import {StatusAlertDispatch} from '../../state/statusAlertState';
import {Brand} from '../../utils/dibkSchemas/models/models';
import getErrorMsg from '../../utils/getErrorMsg';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

export type StatusAlertId = Brand<string, 'statusAlertId'>;

export interface IStatusAlert {
  Id: StatusAlertId;
  FromTime: Date;
  ToTime?: Date;
  Message: string;
  MessageType: 'Error' | 'Info' | 'Warning';
  DeactivationFlag: boolean;
  hideAlert?: boolean;
}

export function getStatusAlerts(dispatch: StatusAlertDispatch) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<{StatusAlerts: IStatusAlert[]}>('/getCurrentStatusAlerts').then(
      (res) => {
        dispatch({type: 'setStatusAlerts', value: res.data.StatusAlerts ? res.data.StatusAlerts : []});
      },
      (err: AxiosError) => {
        appInsights.trackException(
          {
            error: new Error('statusAlertFailed'),
            severityLevel: SeverityLevel.Error,
          },
          {error: getErrorMsg(err), path: window.location.href}
        );
      }
    )
  );
}
