import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {AxiosError, AxiosResponse} from 'axios';
import {appInsights, traceSeverityLevel} from '../../AppInsightsSetup';
import {getConfigValue} from '../../config';
import {Context, Dispatch} from '../../state/applicationStore';
import {ProduktKode} from '../../state/productState';
import getErrorMsg from '../../utils/getErrorMsg';
import {ApplicationId} from './../ebyggesokServices/userOrderService';
import {getAsyncAxiosInstanceWithIdportenHeader, getNoHeaderAxiosInstance} from './axiosService';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {MunicipalityContext} from '../../state/municipalityStore';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';

interface CreatePaymentResponse {
  paymentId: string;
  skipPayment: boolean;
}

interface GetPaymentResponse {
  PaymentStatus: TypePaymentStatus;
}

export enum SubmissionType {
  Nabovarsel = 'nabovarsel',
  UtenAnsvarsrett = 'utenansvarsrett',
}

export type TypePaymentStatus = 'None' | 'PaymentAuthorized';

export interface CreatePaymentRequest {
  email?: string;
  postalCode?: string;
  applicationId?: number;
  produktKode?: ProduktKode;
  submissionType: SubmissionType;
}

let checkoutOptions = {
  checkoutKey: getConfigValue('easyCheckoutKey'), //[Required] Test or Live GUID with dashes
  paymentId: '',
  partnerMerchantNumber: getConfigValue('easyMerchantId'),
  containerId: 'dibs-complete-checkout',
  language: 'nb-NO',
};

let dibsCheckout;

export type CreatePaymentHookResponse = {
  isFetching: boolean;
  isPaymentFailed: boolean;
};

export function useCreatePayment(enabled: boolean, onPaymentError?: () => void): CreatePaymentHookResponse {
  onPaymentError ??= () => {};
  const {
    state: {
      buildingSite,
      payment,
      application,
      nabovarsel,
      questions: {answers},
    },
    dispatch,
  } = React.useContext(Context);
  const history = useHistory();
  const {
    municipalityState: {
      product: {municipalityProduct},
    },
  } = React.useContext(MunicipalityContext);

  const [fetchingPaymentId, setFetchingPaymentId] = React.useState<boolean>(false);
  const submissionType =
    nabovarsel.draftReference !== undefined ? SubmissionType.Nabovarsel : SubmissionType.UtenAnsvarsrett;

  const skipPaymentRedirect = () => {
    //Payment valid, sumbit schema
    if (nabovarsel.draftReference !== undefined) {
      //User has started a nabovarsel draft, go forward to submit nabovarsel
      history.push(ApplicationRoutes.sendNabovarsel.path);
    } else {
      //User has no nabovarsel draft, go forward to submit application
      history.push(ApplicationRoutes.submitSoknad.path);
    }
  };

  React.useEffect(() => {
    //Reset pending state on mount
    if (payment.pending && enabled) {
      dispatch({type: 'setPaymentPending', value: false});
    }
  }, [enabled]);

  React.useEffect(() => {
    const getPostalCode = () => {
      if (answers.TILTAKSHAVER_POSTNR?.value) {
        return answers.TILTAKSHAVER_POSTNR?.value as string;
      }
    };
    const getEmail = () => {
      if (answers.TILTAKSHAVER_EMAIL?.value) {
        return answers.TILTAKSHAVER_EMAIL?.value as string;
      }
    };

    const redirectForward = (paymentId) => {
      history.push(
        InfoPagesRoutes.payment.path +
          `?paymentId=${paymentId}&applicationId=${application.applicationId}&submissionType=${submissionType}`
      );
    };
    if (!enabled) {
      return;
    }
    if (payment.skipPayment && payment.id === undefined) {
      setFetchingPaymentId(false);
      skipPaymentRedirect();
      return;
    }
    if (payment.id === undefined && !payment.pending) {
      const body: CreatePaymentRequest = {
        email: getEmail(),
        postalCode: getPostalCode(),
        applicationId: application.applicationId,
        submissionType: submissionType,
      };
      if (municipalityProduct) {
        body.produktKode = municipalityProduct;
      }
      setFetchingPaymentId(true);
      createPayment(body, application.applicationId!, dispatch, setFetchingPaymentId, onPaymentError!, redirectForward);
    }
    if (payment.id && !payment.pending) {
      // Payment was started previously, we can reuse the payment id
      redirectForward(payment.id);
    }
  }, [
    buildingSite.chosenAddress,
    dispatch,
    payment,
    application.applicationId,
    answers.TILTAKSHAVER_EMAIL,
    answers.TILTAKSHAVER_POSTNR,
    enabled,
  ]);

  return {isFetching: fetchingPaymentId} as CreatePaymentHookResponse;
}

export function createPayment(
  request: CreatePaymentRequest,
  soknadId: ApplicationId,
  dispatch: Dispatch,
  setPaymentPending: (bool: boolean) => void,
  onPaymentError: () => void,
  onSuccessCallback: (paymentId: string) => void
) {
  appInsights.trackTrace(
    {
      message: 'payment create pending ' + soknadId,
    },
    {soknadId: soknadId}
  );
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) => {
    instance
      .post('/createPayment', {
        ...request,
      })
      .then(
        (res: AxiosResponse<CreatePaymentResponse>) => {
          setPaymentPending(false);
          if (res.data.skipPayment && res.data.paymentId === undefined) {
            appInsights.trackTrace(
              {
                message: 'payment skipPayment ' + soknadId,
              },
              {response: JSON.stringify(res.data), soknadId: soknadId}
            );
            dispatch({type: 'setSkipPayment', value: res.data.skipPayment});
            return;
          }
          appInsights.trackTrace({message: 'payment created'}, {paymentId: res.data.paymentId, soknadId: soknadId});
          dispatch({type: 'setPaymentId', value: res.data.paymentId});
          onSuccessCallback(res.data.paymentId);
        },
        (err: AxiosError) => {
          setPaymentPending(false);
          appInsights.trackException(
            {
              error: new Error('createPaymentFailed'),
              severityLevel: SeverityLevel.Error,
            },
            {error: getErrorMsg(err), soknadId: soknadId}
          );
          onPaymentError();
        }
      );
  });
}

export async function getPaymentStatus(applicationId: string, intervalId) {
  return getNoHeaderAxiosInstance()
    .get('/getPayment?ApplicationId=' + applicationId)
    .then(
      (res: AxiosResponse<GetPaymentResponse>) => {
        appInsights.trackTrace(
          {
            message: 'getPaymentStatus' + res.data.PaymentStatus,
            severityLevel: traceSeverityLevel.Information,
          },
          {soknadId: applicationId}
        );
        return res;
      },
      (err: AxiosError) => {
        appInsights.trackException(
          {
            error: new Error('getPaymentFailed'),
            severityLevel: SeverityLevel.Error,
          },
          {error: getErrorMsg(err), applicationId: applicationId}
        );
        clearInterval(intervalId);
      }
    );
}

export function cancelPayment(paymentId: string, soknadId: number, dispatch: Dispatch) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) => {
    instance
      .post('/cancelPayment', {
        paymentId: paymentId,
        soknadId: soknadId,
      })
      .then(
        (res: AxiosResponse<CreatePaymentResponse>) => {
          appInsights.trackTrace(
            {
              message: 'payment cancel success',
              severityLevel: traceSeverityLevel.Information,
            },
            {soknadId: soknadId}
          );
          dispatch({type: 'setPaymentId', value: undefined});
          dispatch({type: 'setPaymentSuccess', value: false});
          dispatch({type: 'setHasCancelledPayment', value: true});
        },
        (err: AxiosError) => {
          appInsights.trackException(
            {
              error: new Error('cancelPaymentFailed'),
              severityLevel: SeverityLevel.Error,
            },
            {error: getErrorMsg(err), soknadId: soknadId}
          );
          //Reset state even when cancel payment failed
          dispatch({type: 'setPaymentId', value: undefined});
          dispatch({type: 'setHasCancelledPayment', value: false});
          dispatch({type: 'setPaymentSuccess', value: false});
        }
      );
  });
}

export function loadEasyScript(id, soknadId: ApplicationId | undefined, onPaymentSuccessCallback: () => void) {
  // @ts-ignore
  if (!window.Dibs) {
    appInsights.trackTrace(
      {
        message: 'payment loadEasyScript start ' + soknadId,
        severityLevel: traceSeverityLevel.Information,
      },
      {soknadId: soknadId}
    );
    const script = document.createElement('script');
    script.src = getConfigValue('easyScriptUrl');
    if (!script.src) {
      appInsights.trackException(
        {
          error: new Error('missingEasyScriptUrl'),
          severityLevel: SeverityLevel.Error,
        },
        {soknadId: soknadId}
      );
    }
    script.async = true;
    script.onload = () => loadingFinished(id, soknadId, onPaymentSuccessCallback);
    document.body.appendChild(script);
  } else {
    appInsights.trackTrace(
      {
        message: 'payment do not need to loadEasyScript ' + soknadId,
        severityLevel: traceSeverityLevel.Information,
      },
      {soknadId: soknadId}
    );
    loadingFinished(id, soknadId, onPaymentSuccessCallback);
  }
}

function loadingFinished(id, soknadId: ApplicationId | undefined, onPaymentSuccessCallback: () => void) {
  appInsights.trackTrace({
    message: 'payment loadEasyScript finished ' + soknadId,
    properties: {soknadId: soknadId, paymentId: id},
    severityLevel: traceSeverityLevel.Information,
  });

  checkoutOptions.paymentId = id;
  // @ts-ignore
  dibsCheckout = new Dibs.Checkout(checkoutOptions);

  dibsCheckout.on('payment-completed', onPaymentSuccessCallback);
}
