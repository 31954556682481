import {appInsights} from '../../../AppInsightsSetup';
import {getFilteredSearchWords} from '../../../components/ContentSearch/helpers';
import {getTextObject} from '../../../texts/textObjectHelpers';

export const onSearchChanged = (
  searchText: string,
  initArticlesList: string[],
  setArticlesToDisplay: (articles: string[]) => void,
  setHasActiveSearchFilter: (hasSearch: boolean) => void
) => {
  if (searchText !== '') {
    trackUserSearch(searchText);
    setHasActiveSearchFilter(true);
    const searchWords = getFilteredSearchWords(searchText);
    const articlesContainingString = initArticlesList.filter((articleName) => {
      const article_content =
        articleName &&
        getTextObject()
          .articles[articleName].split('[start]')[1]
          .replace(/-row-\w+.*-row-/, '')
          .replace('[kom-i-gang]', '')
          .replace('[soknad-prossess]', '');

      let hasMatch = true;
      searchWords.forEach((word) => {
        if (
          !articleName.toLowerCase().includes(word.toLowerCase()) &&
          !article_content.toLowerCase().includes(word.toLowerCase())
        ) {
          hasMatch = false;
        }
      });
      return hasMatch;
    });
    setArticlesToDisplay(articlesContainingString);
  } else {
    setHasActiveSearchFilter(false);
    setArticlesToDisplay(initArticlesList);
  }
};

const trackUserSearch = (searchText: string) => {
  appInsights.trackEvent({name: 'faq-search'}, {searchText: searchText});
};
