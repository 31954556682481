import React from 'react';
import {saveStore} from '../utils/sessionstorage';
import * as chosenMunicipality from './chosenMunicipalityState';
import * as product from './productState';

export const initialMunicipalityState: IMunicipalityContextProps['municipalityState'] = {
  product: product.initialState,
  chosenMunicipality: chosenMunicipality.initialChosenMunicipalityState,
};

export const municipalityReducer = (
  state: IMunicipalityContextProps['municipalityState'],
  action: IDispatchInput
): IMunicipalityContextProps['municipalityState'] => {
  //console.log(action);
  //console.log(state);
  const newState = {
    product: product.reducer(state.product, action as product.Action),
    chosenMunicipality: chosenMunicipality.reducer(state.chosenMunicipality, action as chosenMunicipality.Action),
  };

  saveStore(newState, 'municipalityStore');

  return newState;
};

export type IDispatchInput = product.Action | chosenMunicipality.Action;

interface IMunicipalityContextProps {
  municipalityState: {
    product: product.ProductState;
    chosenMunicipality: chosenMunicipality.ChosenMunicipalityState;
  };
  municipalityDispatch: (dispatchInput: IDispatchInput) => void;
}

export type MunicipalityState = IMunicipalityContextProps['municipalityState'];
export type MunicipalityDispatch = IMunicipalityContextProps['municipalityDispatch'];

export const MunicipalityContext = React.createContext({} as IMunicipalityContextProps);
