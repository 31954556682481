import React from 'react';
import {Brand} from 'utility-types';
import {appInsights} from '../AppInsightsSetup';
import {InfoPagesRoutes} from '../utils/routes/InfoPagesRoutes';
import {saveStore} from '../utils/sessionstorage';
export type Personnummer = Brand<string, 'Personnummer'>;

export interface UserState {
  inactivityLogout: boolean;
  userId?: Personnummer;
  isLoggedIn?: boolean;
  displayLoginErrorPopup: boolean;
  loginErrorPopupText?: string;
  loginPending: boolean;
  access: {pending: boolean; accessIsControlled: boolean};
  refreshTokenIntervalId?: any;
  routeBeforeSignIn: string;
  routeBeforeSignOut: string;
  severeError: severeError;
  saveAnswerError: saveAnswerError;
}

export type saveAnswerError = {
  hasError: boolean;
};

export type severeError = {
  msg?: string;
  excludeDefaultText?: boolean;
  hideTryAgainButton?: boolean;
  includeContactButton?: boolean;
  hasError?: boolean;
};

export const initialUserState: UserState = {
  displayLoginErrorPopup: false,
  loginPending: false,
  access: {pending: false, accessIsControlled: false},
  routeBeforeSignIn: InfoPagesRoutes.base.path,
  routeBeforeSignOut: InfoPagesRoutes.base.path,
  inactivityLogout: false,
  severeError: {},
  saveAnswerError: {hasError: false},
};

export type userAction =
  | {
      type: 'setUserId';
      value?: Personnummer;
    }
  | {
      type: 'displayLoginErrorPopup';
      value?: {msg?: string; reason?: string};
    }
  | {
      type: 'hideLoginErrorPopup';
    }
  | {
      type: 'setLoginPending';
    }
  | {
      type: 'checkAccessPending';
    }
  | {
      type: 'accessIsControlled';
      value: boolean;
    }
  | {
      type: 'setInactivityLogout';
      value: boolean;
    }
  | {
      type: 'resetUser';
    }
  | {
      type: 'setRefreshTokenIntervalId';
      value: any;
    }
  | {
      type: 'setRouteBeforeSignIn';
      value: string;
    }
  | {
      type: 'setRouteBeforeSignOut';
      value: string;
    }
  | {
      type: 'setSevereError';
      value: severeError;
    }
  | {
      type: 'resetSevereError';
    }
  | {
      type: 'setSaveAnswerError';
      value: boolean;
    };

const reducer = (state: UserState, action: userAction): UserState => {
  switch (action.type) {
    case 'setUserId':
      return {
        ...state,
        userId: action.value,
        isLoggedIn: true,
        displayLoginErrorPopup: false,
        loginPending: false,
        loginErrorPopupText: undefined,
      };
    case 'displayLoginErrorPopup': {
      appInsights.trackEvent({name: 'LoginError reason: ' + action.value?.reason});
      return {
        ...state,
        userId: undefined,
        isLoggedIn: false,
        displayLoginErrorPopup: true,
        loginPending: false,
        loginErrorPopupText: action.value?.msg || undefined,
      };
    }
    case 'setSaveAnswerError':
      return {
        ...state,
        saveAnswerError: {hasError: action.value},
      };
    case 'setSevereError':
      return {
        ...state,
        severeError: action.value,
      };
    case 'resetSevereError':
      return {
        ...state,
        severeError: {},
      };
    case 'setLoginPending': {
      return {...state, loginPending: true};
    }
    case 'checkAccessPending': {
      return {...state, access: {pending: true, accessIsControlled: false}};
    }
    case 'accessIsControlled': {
      return {...state, access: {pending: false, accessIsControlled: action.value}};
    }
    case 'hideLoginErrorPopup': {
      return {...state, displayLoginErrorPopup: false, loginErrorPopupText: undefined};
    }
    case 'resetUser': {
      console.info('reset user, interval id is:', state.refreshTokenIntervalId);
      if (state.refreshTokenIntervalId) {
        clearInterval(state.refreshTokenIntervalId);
      }
      return {...initialUserState, inactivityLogout: state.inactivityLogout};
    }
    case 'setRefreshTokenIntervalId': {
      return {...state, refreshTokenIntervalId: action.value};
    }
    case 'setRouteBeforeSignIn': {
      return {
        ...state,
        routeBeforeSignIn: action.value,
      };
    }
    case 'setRouteBeforeSignOut': {
      return {
        ...state,
        routeBeforeSignOut: action.value,
      };
    }
    case 'setInactivityLogout': {
      return {
        ...state,
        inactivityLogout: action.value,
      };
    }
    default:
      return state;
  }
};

export const userReducer = (state: UserState, action: userAction): IUserContextProps['userState'] => {
  const newState = reducer(state, action);
  saveStore(newState, 'userStore');
  return newState;
};

export type IDispatchInput = userAction;

interface IUserContextProps {
  userState: UserState;
  userDispatch: (dispatchInput: IDispatchInput) => void;
}

export type UserDispatch = IUserContextProps['userDispatch'];

export const UserContext = React.createContext({} as IUserContextProps);
