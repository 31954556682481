import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {appInsights} from '../../AppInsightsSetup';
import {Product} from '../../state/productState';
import getErrorMsg from '../../utils/getErrorMsg';
import {getAxiosInstanceWithApiKeyHeader} from './axiosService';
import {useQuery} from '@tanstack/react-query';

export const productsQueryKey = ['productsQueryKey'];
function useProducts() {
  const getProducts = async () => {
    try {
      const response = await getAxiosInstanceWithApiKeyHeader().get<{Produkter: Product[]}>('/hentProdukter');

      return response.data.Produkter || [];
    } catch (error: any) {
      appInsights.trackException(
        {
          error: new Error('fetchproductsFailed'),
          severityLevel: SeverityLevel.Error,
        },
        {error: getErrorMsg(error), path: window.location.href}
      );
      throw error;
    }
  };
  const query = useQuery(productsQueryKey, getProducts, {refetchOnWindowFocus: false, refetchOnMount: false});
  return {...query, availableProducts: query.data};
}

export default useProducts;
