import React from 'react';
import {Link} from 'react-router-dom';
import {appInsights} from '../../../AppInsightsSetup';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import './ArticleCard.scss';

type Props = {
  header: string;
  name: string;
  ingress?: string;
  imgSrc: string;
  size?: 'small' | 'large';
  newPage?: boolean;
};

const ArticleCard = ({header, ingress, imgSrc, name, size, newPage}: Props) => {
  const newPageProps = newPage ? {target: '_blank', rel: 'noopener norefferer'} : {};

  const trackArticleClick = () => {
    appInsights.trackEvent({name: `EnterArticle ${name}`}, {fromPage: window.location.pathname});
  };

  return (
    <Link
      onClick={trackArticleClick}
      {...newPageProps}
      to={InfoPagesRoutes.article.path + '/' + name}
      className={`ArticleCard ${size || 'large'}`}
    >
      <div className={`imgContainer ${size || 'large'}`}>
        <img alt='' src={imgSrc} />
      </div>
      <div className={`textContainer ${size || 'large'}`}>
        <NkTypography color='white' type={size === 'small' ? 'smallParagraph' : 'normalParagraph'}>
          {header}
        </NkTypography>
        <NkTypography color='white' type={'smallParagraph'}>
          {ingress}
        </NkTypography>
      </div>
    </Link>
  );
};
export default ArticleCard;
