import {getConfigValue} from '../config';
import {KommuneNummer} from '../utils/dibkSchemas/models/models';
import {Brand} from 'utility-types';

export type Kommuneinfo = {KommuneName: {nor: string}; KommuneNumber: KommuneNummer};

export interface ProductState {
  municipalityProduct: ProduktKode | undefined;
  price: number | undefined;
  selectedMunicipalityPrice: number | undefined;
}

export type ProduktKode = Brand<string, 'produktKode'>;

export type Product = {
  BestillingsSkjema: string[];
  Betingelser: Betingelse[];
  ProduktKode: ProduktKode;
  Beskrivelse: string;
  Detaljert: string;
  Pris: number;
};

type Betingelse = {
  For: string[];
  SendtMed: string[];
  BegrensetTil?: {Liste: KommuneNummer[]};
};

export const initialState: ProductState = {
  municipalityProduct: undefined,
  price: undefined,
  selectedMunicipalityPrice: undefined,
};

export type Action =
  | {
      type: 'setMunicipalityProduct';
      value: {produktKode: ProduktKode; pris: number};
    }
  | {
      type: 'setPrice';
      value: number | undefined;
    };

export const reducer = (state: ProductState = initialState, action: Action): ProductState => {
  switch (action.type) {
    case 'setMunicipalityProduct':
      return {...state, municipalityProduct: action.value.produktKode, price: action.value.pris};
    case 'setPrice':
      return {
        ...state,
        selectedMunicipalityPrice: action.value,
      };
    default:
      return state;
  }
};

export const getMunicipalityProductAndPrice = (
  availableProducts: Product[],
  chosenKommune: KommuneNummer
): {produktKode: ProduktKode; pris: number} | false => {
  let found: {produktKode: ProduktKode; pris: number} | false = false;
  availableProducts.forEach((p) => {
    p.Betingelser.forEach((b) => {
      if (b.BegrensetTil && b.BegrensetTil.Liste.includes(chosenKommune)) {
        found = {produktKode: p.ProduktKode, pris: p.Pris};
      }
    });
  });
  return found;
};
export const getMunicipalityPrice = (
  availableProducts: Product[],
  chosenKommune: KommuneNummer
): number | undefined => {
  const defaultPrice = getConfigValue('ebyggesokPrice');
  let found: number = parseInt(defaultPrice);
  availableProducts.forEach((p) => {
    p.Betingelser.forEach((b) => {
      if (b.BegrensetTil && b.BegrensetTil.Liste.includes(chosenKommune)) {
        found = p.Pris;
      }
    });
  });
  return found;
};
