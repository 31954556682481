import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {useQuery} from '@tanstack/react-query';
import {appInsights} from '../../AppInsightsSetup';
import getErrorMsg from '../../utils/getErrorMsg';
import {Customer} from '../configs/customerConfig';
import {getAxiosInstanceWithIdportenHeader} from './axiosService';

export const useCustomers = () => {
  const getAllCustomers = async () => {
    try {
      const response = await getAxiosInstanceWithIdportenHeader().get<Customer[]>('/GetAllCustomers');

      return response.data;
    } catch (error: any) {
      appInsights.trackException(
        {
          error: new Error('GetAllCustomers'),
          severityLevel: SeverityLevel.Error,
        },
        {error: getErrorMsg(error), path: window.location.href}
      );
      throw error;
    }
  };

  const query = useQuery<Customer[]>({
    queryKey: ['allCustomersQueryKey'],
    queryFn: getAllCustomers,
    refetchOnWindowFocus: false,
  });
  return {...query, customerMunicipalities: query.data ?? []};
};
