import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {AxiosError} from 'axios';
import {appInsights} from '../../AppInsightsSetup';
import {getConfigValue} from '../../config';
import {NotifyMethod} from '../../scenes/NabovarselSvar/components/ManualNeighbours';
import {AltinnDraftReference, ApplicationState} from '../../state/applicationState';
import {Attachment, AttachmentId} from '../../state/attachmentsState';
import {NabovarselXmlId, NabovarselState} from '../../state/nabovarselState';
import {Answers} from '../../state/questionTypes';
import {Dispatch} from '../../state/applicationStore';
import {NaboTeig, ValidAttachmentType} from '../../utils/dibkSchemas/models/models';
import {ForberedNabovarselRequest, Vedlegg} from '../../utils/dibkSchemas/models/nabovarsel';
import {getVedleggSoknad} from '../../utils/dibkSchemas/soknad';
import getErrorMsg from '../../utils/getErrorMsg';
import {AttachmentValidationString} from './soknadService';
import {ProduktKode} from '../../state/productState';
import {AltinnReference, ApplicationId} from './userOrderService';
import {InnsendingStatus} from '../utils/InnsendingStatus';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

export interface IncompleteNeighbour {
  IdNumber: string;
  Name: string;
  Address: string;
  PostNumber: string;
  PostPlace: string;
  Property: string;
  PropertyAddress?: string;
  SluttbrukersystemVaarReferanse: string;
}

export interface ManualNeighbour {
  Id: number;
  Name: string;
  Address: string;
  PostNumber: string;
  PostPlace: string;
  Property: string;
  PropertyAddress?: string;
  NotifiedDate?: string;
  NotificationMethod?: NotifyMethod | '';
  SluttbrukersystemVaarReferanse: string;
  HasMerknad: boolean | null;
  Attachment?: {Url: string | null; Name?: string; AttachmentId?: AttachmentId; Type?: string; FileSize?: number};
}

export interface PrepareNabovarselResponse {
  NabovarselReference: NabovarselXmlId;
  SuccessfulValidation: boolean;
  NeighboursCount: number;
  Errors: ForberedEnaboErrors;
  IncompleteNeighbours: IncompleteNeighbour[];
}

export type ForberedEnaboErrors = {
  Errors: number;
  Warnings: number;
  messages: MessageError[];
};

export type MessageError = {
  message: AttachmentValidationString;
  messageType: 'WARNING' | 'ERROR';
  reference?: string;
  xpathField?: string;
};

export type Eiendom = {
  Adresse?: string;
  Postnr?: string;
  Poststed?: string;
  Knr?: string;
  Gnr?: string;
  Bnr?: string;
  Fnr?: string;
  Snr?: string;
};

export type NabovarselStatus = {
  AntallInnsendinger: number;
  AntallFeilInnsendinger: number;
  Svar: {
    Navn: string;
    HarMerknad: boolean;
    MerknadsTekst?: string;
    Eiendommer: Eiendom[];
    PdfUrl: string;
    MerknadsVedlegg?: string[];
    SluttbrukersystemVaarReferanse: string;
  }[];
  InnsendingsStatus: InnsendingStatus;
  Dato: string;
  IsReady: boolean;
  Kvittering: string;
  DistribuertVarsel: string;
  FeiledeNaboer: IncompleteNeighbour[];
};

export type NabovarselFrist = {
  Days: number;
  Reason: 'AllNabovarselsAnswered' | 'TimeExpired' | 'ManualNeighborNotNotified' | 'WaitingAnswerNotExpired';
};

export const validateNabovarsel = (
  input: ForberedNabovarselRequest,
  success: (res: PrepareNabovarselResponse) => any,
  failure: (error) => void,
  saveNabovarsel = true
) => {
  getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance
      .post(`/forbereduanabovarselordre`, {
        ...input,
        Lagre: saveNabovarsel,
      })
      .then(
        (res) => {
          success(res.data);
        },
        (error: AxiosError) => {
          failure(error);
        }
      )
  );
};

export function fetchManualNeighbours(applicationId: number) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<ManualNeighbour[]>(`/HentManuelleNaboer?applicationId=${applicationId}`)
  );
}

export function setIncompleteNeighbours(applicationId: number, incompleteNeighbours: IncompleteNeighbour[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LagreUfullstendigNaboer`, {
      SoknadId: applicationId,
      IncompleteNeighbours: incompleteNeighbours,
    })
  );
}

export function saveManualNeighbours(applicationId: number, manualNeighbours: IncompleteNeighbour[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LagreManuelleNaboer`, {
      SoknadId: applicationId,
      ManuelleNaboer: manualNeighbours,
    })
  );
}

export function updateManualNeighbour(
  applicationId: ApplicationId,
  updatedManualNeighbour: ManualNeighbour,
  arCode: string,
  dispatch
) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance
      .post(`/OppdaterManuellNabo`, {
        SoknadId: applicationId,
        ManuellNabo: updatedManualNeighbour,
      })
      .then(() =>
        getNabovarselFrist(arCode, applicationId!).then(
          (res) => {
            dispatch({type: 'setNabovarselFrist', value: res.data});
          },
          (error: AxiosError) => {
            appInsights.trackException(
              {
                error: new Error('getNabovarselFristFailed'),
                severityLevel: SeverityLevel.Error,
              },
              {error: getErrorMsg(error), soknadId: applicationId}
            );
          }
        )
      )
  );
}

export function getNabovarselSvar(arCode: string, neighbourXmlId: string, applicationId: ApplicationId) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post<NabovarselStatus>(`/hentNabovarselSvarIdporten`, {
      ArkivReferanse: arCode,
      SkjemaId: neighbourXmlId,
      CustomerId: getConfigValue('customerIdInnsendingstjenesten'),
      SoknadId: applicationId,
    })
  );
}

export function getNabovarselFrist(arCode: string, applicationId: ApplicationId) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<NabovarselFrist>(`/hentNabovarselFrist?SoknadId=${applicationId}&ARcode=${arCode}`)
  );
}

export function addFileToManuelNeighbour(
  applicationId: number,
  manualNeighbourId: number,
  file: File,
  formData: FormData
) {
  var type: ValidAttachmentType = 'Nabomerknader';
  return getAsyncAxiosInstanceWithIdportenHeader(undefined, {'Content-Type': 'multipart/form-data'}).then((instance) =>
    instance.post<{
      FileSize: number;
      DownloadUrl: string;
      AttachmentId: AttachmentId;
    }>(
      '/OppdaterManuellNaboVedlegg?applicationId=' +
        applicationId +
        '&manualNeighbourId=' +
        manualNeighbourId +
        '&type=' +
        type,
      formData
    )
  );
}

export function deleteFileFromManuelNeighbour(applicationId: number, manualNeighbourId: number, attachmentId: string) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(
      '/FjernManuellNaboVedlegg?applicationId=' +
        applicationId +
        '&manualNeighbourId=' +
        manualNeighbourId +
        '&attachmentId=' +
        attachmentId
    )
  );
}

export function setNeighbourTeigs(applicationId: number, neighbourTeigs: NaboTeig[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LagreNaboTeiger`, {
      SoknadId: applicationId,
      NaboTeiger: neighbourTeigs.map((i) => {
        return {...i, geometry: JSON.stringify(i.geometry), propertyIds: JSON.stringify(i.propertyIds)};
      }),
    })
  );
}

export function removeNeighbourTeigs(applicationId: number, teigIds: number[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/FjernNaboTeiger`, {
      ApplicationId: applicationId,
      TeigIds: teigIds,
    })
  );
}

export function addNeighbourTeigs(applicationId: number, teigs: NaboTeig[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LeggTilNaboTeiger`, {
      ApplicationId: applicationId,
      NaboTeiger: teigs.map((i) => {
        return {...i, geometry: JSON.stringify(i.geometry), propertyIds: JSON.stringify(i.propertyIds)};
      }),
    })
  );
}

export function createOrUpdateNabovarselDraft(
  nabovarsel: NabovarselState,
  attachments: Attachment[],
  application: ApplicationState,
  answers: Answers,
  dispatch: Dispatch,
  failure: (error) => void,
  onSuccess?: () => void
) {
  const neighbourAttachments = attachments.filter((a) => a.includeInNabovarsel);
  if (nabovarsel.draftReference) {
    updateDraftNabovarsel(
      nabovarsel.draftReference,
      application.applicationId!,
      nabovarsel.nabovarselXmlId!,
      getVedleggSoknad(neighbourAttachments, answers)
    ).then(
      (res) => {
        dispatch({
          type: 'setDraftReference',
          value: res.data.AltinnDraftReference,
        });
        dispatch({
          type: 'setPdfDraft',
          value: res.data.AltinnPdfUrl,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        failure(error);
      }
    );
  } else {
    createDraftNabovarsel(
      application.applicationId!,
      nabovarsel.nabovarselXmlId!,
      getVedleggSoknad(neighbourAttachments, answers)
    ).then(
      (res) => {
        dispatch({
          type: 'setDraftReference',
          value: res.data.AltinnDraftReference,
        });
        dispatch({
          type: 'setPdfDraft',
          value: res.data.AltinnPdfUrl,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        failure(error);
      }
    );
  }
}

const createDraftNabovarsel = (
  soknadId: number,
  skjemaId: NabovarselXmlId,
  attachments: Vedlegg[]
): Promise<{data: {AltinnDraftReference: AltinnDraftReference; AltinnPdfUrl: string}}> => {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/CreateNabovarselDraft`, {
      SoknadId: soknadId,
      SkjemaId: skjemaId,
      Customerid: getConfigValue('customerIdInnsendingstjenesten'),
      Vedlegg: attachments,
      TransactionId: 'draft',
    })
  );
};

const updateDraftNabovarsel = (
  draftReference: AltinnDraftReference,
  soknadId: number,
  skjemaId: NabovarselXmlId,
  attachments: Vedlegg[]
): Promise<{data: {AltinnDraftReference: AltinnDraftReference; AltinnPdfUrl: string}}> => {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.put(`/UpdateNabovarselDraft`, {
      DraftReference: draftReference,
      SoknadId: soknadId,
      SkjemaId: skjemaId,
      Customerid: getConfigValue('customerIdInnsendingstjenesten'),
      Vedlegg: attachments,
      TransactionId: 'draft',
    })
  );
};

export const submitNabovarselToDibk = (
  draftReference: AltinnDraftReference,
  soknadId: number,
  skjemaId: NabovarselXmlId,
  attachments: Vedlegg[],
  paymentId: string | undefined,
  produktKode?: ProduktKode
): Promise<{data: {AltinnReference: AltinnReference; ReceiversCount: number}}> => {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/SendNabovarsel`, {
      DraftReference: draftReference,
      SoknadId: soknadId,
      SkjemaId: skjemaId,
      Customerid: getConfigValue('customerIdInnsendingstjenesten'),
      Vedlegg: attachments,
      TransactionId: paymentId,
      ProduktKode: produktKode,
    })
  );
};
