import {ugradOption, validGradOfUtnyttingTypes} from './ugrad';
import {appInsights} from '../AppInsightsSetup';

export const ugradOptionToKode = (
  ugrad: ugradOption
): {
  kodeverdi: keyof typeof validGradOfUtnyttingTypes;
  kodebeskrivelse: string;
} => {
  var kodeVerdi: keyof typeof validGradOfUtnyttingTypes;
  if (ugrad === 'Bebygd areal, BYA') {
    kodeVerdi = 'BYA';
  } else if (ugrad === 'Bruksareal, BRA') {
    kodeVerdi = 'BRA';
  } else if (ugrad === 'Prosent bruksareal, %BRA') {
    kodeVerdi = '%BRA';
  } else if (ugrad === 'Prosent bebygd areal, %BYA') {
    kodeVerdi = '%BYA';
  } else if (ugrad === 'Prosent tomteutnyttelse, %TU') {
    kodeVerdi = '%TU';
  } else if (ugrad === 'Totalt bruksareal, T-BRA') {
    kodeVerdi = 'T-BRA';
  } else if (ugrad === 'U-grad') {
    appInsights.trackEvent({name: 'U-grad selected'});
    throw new Error('not supported ugradvalue');
  } else {
    appInsights.trackEvent({name: 'Ingen av disse selected'});
    throw new Error('unknow ugradvalue');
  }

  return {
    kodeverdi: kodeVerdi,
    kodebeskrivelse: validGradOfUtnyttingTypes[kodeVerdi],
  };
};
