import {Person} from '../../utils/dibkSchemas/models/nabovarsel';
import {getConfigValue} from '../../config';
import {MatrikkelNummer, OrganisasjonsNummer} from '../../utils/dibkSchemas/models/models';
import {Brand} from 'utility-types';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

export type AltinnReference = Brand<string, 'AltinnReference'>;
export type ApplicationId = Brand<number, 'ApplicationId'>;
export interface MinEiendomResponse {
  Tinglyste: SelveierProperty[] | [];
  Borettslagsandeler: BorettsProperty[] | [];
}

export interface SelveierProperty {
  Kommune: Kommune;
  Eiendom: TinglystEiendom;
  testReason?: string; //TO KNOW WHY TO TEST PROPERTY
}
interface TinglystEiendom {
  Kommunenummer: string;
  Gardsnummer: number;
  Bruksnummer: number;
  Festenummer: number;
  Seksjonsnummer: number;
  Komplett: MatrikkelNummer;
}
interface Kommune {
  Kommunenummer: string;
  Kommunenavn: string;
}

interface KommuneBorett {
  Kommunenummer?: string;
  Kommunenavn?: string;
}
export interface BorettsProperty {
  Borett: {
    BorettslagId: OrganisasjonsNummer;
    Andelsnummer: number;
  };
  Kommune: KommuneBorett;
  testReason?: string; //TO KNOW WHY TO TEST PROPERTY
}
interface SubmitOrderRequest {
  CustomerId: string;
  Typen: OrderType;
  ArkivReferansen: string;
  Betalingstoken?: string; //paymentId
  Hovedeiendommer: MatrikkelNummer[];
  Naboeiendommer?: MatrikkelNummer[];
  Soeker: Person;
  KvitteringsEpost?: string;
  AntallNabovarsler?: number;
  SkjemaId: string; //XML skjema id
  ApplicationId: number | undefined;
  PaymentStatus: PaymentStatus;
}

export type OrderType = 'UaNabovarsel' | 'Byggesoknad' | 'Ferdigattest';
type PaymentStatus = 'PaymentRegistered' | 'None';

//Only for registering submits that can be connected to payment
export function submitOrder(
  altinnReference: AltinnReference,
  matrikkelIds: MatrikkelNummer[],
  soeker: Person,
  receiptEmail: string,
  skjemaId: string,
  innsendingsType: 'UaNabovarsel' | 'Byggesoknad' | 'Ferdigattest',
  paymentStatus: PaymentStatus,
  paymentId?: string,
  applicationId?: ApplicationId,
  neighboursId?: MatrikkelNummer[],
  antallnabovarsler?: number
) {
  var payload: SubmitOrderRequest = {
    CustomerId: getConfigValue('customerIdInnsendingstjenesten'),
    Typen: innsendingsType,
    ArkivReferansen: altinnReference,
    Hovedeiendommer: matrikkelIds,
    Naboeiendommer: neighboursId,
    Soeker: soeker,
    AntallNabovarsler: antallnabovarsler,
    KvitteringsEpost: receiptEmail,
    SkjemaId: skjemaId,
    ApplicationId: applicationId,
    PaymentStatus: paymentStatus,
    Betalingstoken: paymentId,
  };
  getAsyncAxiosInstanceWithIdportenHeader().then((instance) => instance.post(`/registerstatusinnsending`, payload));
}

export async function createOrGetUser() {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<{Id: string} | null>(`/hentInnloggetBruker/`)
  );
}

export async function fetchOwnedProperties() {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<MinEiendomResponse>('/hentMineEiendommer')
  );
}
