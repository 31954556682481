import * as React from 'react';
import './footer.scss';
import NkTypography from '../NkTypography/NkTypography';
import NkLink from '../NkLink/NkLink';
import NkButton from '../Button/NkButton';
import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import {appInsights} from '../../AppInsightsSetup';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import DownloadIcon from '../../images/download.svg';

type Props = {whiteFooterCityBackground?: boolean};
const Footer = (props: Props) => {
  const paymentOptions = (
    <div className='sl-footer__content--payment'>
      <img
        width='182'
        height='43'
        alt='Norkart logo'
        src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606984455/grad_wcmgoe.svg'
      />
      <NkTypography style={{color: 'white', maxWidth: 220, marginBottom: 10, marginTop: 10}} type='smallParagraph'>
        {`© ${new Date().getFullYear()} Norkart AS. Alle rettigheter forbeholdes.`}
        <NkLink darkmode href={'/personvern'} size='small'>
          Personvernerklæring
        </NkLink>
      </NkTypography>
      <NkTypography type='smallParagraph' style={{color: 'white', maxWidth: 220, marginBottom: 10}}>
        Orgnr: NO 934 161 181
      </NkTypography>
      <NkButton
        type='link'
        darkmode
        className='cookies-setting-link'
        onClick={() => (window as any)?.CookieConsent?.renew()}
      >
        Innstillinger for informasjonskapsler
      </NkButton>
      <div style={{display: 'flex', paddingTop: 10}}>
        <NkLink darkmode href='https://kunnskapsbase.gisline.no/hc/no/article_attachments/21941138038673'>
          {'Tilgjengelighetserklæring'}
        </NkLink>
        <img src={DownloadIcon} style={{height: 20, paddingLeft: 10}} alt='nedlaste ikon' />
      </div>
      <div className='line' style={{backgroundColor: '#434245', height: 2, marginTop: 14}}></div>
      <img
        width={180}
        style={{marginTop: 20, marginBottom: 10, height: 80}}
        alt='Leverandør av fellestjenesterbygg logo'
        src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1651742695/eBygges%C3%B8k%20UA/dibk_logo_check.svg'
      />
      <div className='card-logos'>
        {/* Vipps, Visa, Mastercard, osv.  */}
        <img
          width='77'
          height='43'
          src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606984684/Visa-Logo-2006_2014_vupaqv.png'
          alt='visa logo'
          style={{borderRadius: '2px'}}
        />
        <img
          width='60'
          height='43'
          src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606985488/mastercard_h5drpb.jpg'
          alt='mastercard logo'
        />
        <img src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606985544/vipps_mulcug.jpg' alt='vipps logo' />
      </div>
    </div>
  );

  const aboutSection = (
    <div className='sl-footer__content--info'>
      <div className='sl-footer__content--info heading-misc'>
        {/* Informasjon om eByggesøk og Norkart */}
        <NkTypography tag='p' style={{color: 'white', marginBottom: 22}} type='heading3'>
          Kontakt oss
        </NkTypography>
        <NkTypography type='normalParagraph' style={{color: 'white'}}>
          Spørsmål til hvordan eByggesøk fungerer eller opplever tekniske problemer?
        </NkTypography>
        <NkLink
          darkmode
          newPage
          href={InfoPagesRoutes.faq.path}
          onClick={() => appInsights.trackEvent({name: 'ofteStilteSpm footer'})}
        >
          Se våre ofte stilte spørsmål
        </NkLink>
        <NkTypography type='normalParagraph' style={{color: 'white'}}>
          eller send mail til
        </NkTypography>
        <NkLink darkmode newPage href={'mailto:ebyggesok@norkart.no'}>
          ebyggesok@norkart.no
        </NkLink>
        <NkTypography type='normalParagraph' style={{color: 'white', marginTop: 18}}>
          Har du spørsmål om din byggesøknad er det din kommune som kan svare på det.
        </NkTypography>
      </div>
    </div>
  );

  const conditions = (
    <div className='sl-footer__content--terms'>
      <div className='sl-footer__content--terms heading-misc'>
        <NkTypography tag='p' style={{color: 'white', marginBottom: 22}} type='heading3'>
          Hold deg oppdatert
        </NkTypography>
        <NkTypography type='normalParagraph' style={{color: 'white', maxWidth: 300, marginBottom: 20}}>
          Bli varslet når det kommer støtte for flere type søknader og andre nyheter.
        </NkTypography>
        <NkLink
          darkmode
          buttonStyle
          style={{marginBottom: 22, maxWidth: 200}}
          href={ApplicationRoutes.nyhetsbrev.path}
          newPage
          onClick={() => {
            appInsights.trackEvent({name: 'Nyhetsbrev påmelding'});
          }}
        >
          Meld deg på nyhetsbrev
        </NkLink>
      </div>
    </div>
  );

  return (
    <div className='img_and_footer_container' style={props.whiteFooterCityBackground ? {backgroundColor: 'white'} : {}}>
      <div style={{width: '100%'}}>
        <img
          style={{width: '100%', height: '100%'}}
          className='landsby-img'
          alt='Illustrasjon. Landsby'
          src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606989208/trees_front_jetfw3.svg'
        />
      </div>
      <footer className='sl-footer'>
        <div className='sl-footer__content'>
          {paymentOptions}
          {aboutSection}
          {conditions}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
